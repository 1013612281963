import React, {Component, Suspense} from "react";
import Lottie from "react-lottie";
//import Lottie from "react-lottie-player"; //TODO update lottie with this
import Loading from "../../containers/loading/Loading";

export default class DisplayLottie extends Component {
  render() {
    const animationData = this.props.animationData;
    const animationHeight = this.props.animationHeight ? this.props.animationHeight : 512;
    const animationWidth = this.props.animationWidth ? this.props.animationWidth : 512;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData
    };

    return (
      <Suspense fallback={<Loading />}>
        <Lottie options={defaultOptions} isClickToPauseDisabled={true} height={animationHeight} width={animationWidth} />
      </Suspense>
    );
  }
}
