import React, {useContext} from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  educationInfo,
  workExperiences,
  skillsSection,
  openSource,
  blogSection,
  talkSection,
  achievementSection,
  papersSection
} from "../../portfolio";

var pause = false;
var cursor = true;
var speed = 1000;
setInterval(() => {
  if (document.getElementById("logo__cursor")){
    if (!pause) {
      if(cursor) {
        document.getElementById('logo__cursor').style.opacity = 0;
        cursor = false;
      } else {
        document.getElementById('logo__cursor').style.opacity = 1;
        cursor = true;
      }
    } else {
      document.getElementById('logo__cursor').style.opacity = 0;
      cursor = false;
    }
  }
}, speed);

function Header({isTerminalOpen, onTerminalShow, onTerminalClose, cursorBlink}) {
  const {isDark} = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewEducation = educationInfo.display;
  const viewAchievement = achievementSection.display;
  const viewPapers = papersSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;

  const handleOpenTerminal = event => {
    onTerminalShow();
    document.getElementById('logo__cursor').style.opacity = 0;
  };

  const handleCloseTerminal = event => {
    onTerminalClose();
  };

  pause = isTerminalOpen;

  return (    
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
      <span className="logo-block">
        <a href="/" className="logo">
          <span className="grey-color">guest@</span>
          <span className="logo-name">{greeting.username}</span>
        </a>
        <span className="logo"  onClick={isTerminalOpen ? handleCloseTerminal: handleOpenTerminal}>
        <span className="grey-color" style={{"fontSize": "0.8em"}}>~ $ </span>
        <span className="grey-color" id="logo__cursor" style={{
          width: "10px !important",
          background: "#20C20E",
          display: "inline-block", 
          "marginLeft": "5px",
          "borderRadius": "1px"
          }}>&nbsp; &nbsp;
        </span>
        </span>
        </span>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{color: "white"}}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <a href="#skills">Skills</a>
            </li>
          )}
          {viewOpenSource && (
            <li>
              <a href="#opensource">Opensource</a>
            </li>
          )}
          {viewEducation && (
            <li>
              <a href="#education">Education</a>
            </li>
          )}
          {viewPapers && (
            <li>
              <a href="#papers">Papers</a>
            </li>
          )}
          {viewExperience && (
            <li>
              <a href="#experience">Work Experiences</a>
            </li>
          )}
          {viewAchievement && (
            <li>
              <a href="#achievements">Achievements</a>
            </li>
          )}
          {viewBlog && (
            <li>
              <a href="#blogs">Blog</a>
            </li>
          )}
          {viewTalks && (
            <li>
              <a href="#talks">Talks</a>
            </li>
          )}
          
          <li>
            <a href="#contact">Contact Me</a>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#mycontact">
              <ToggleSwitch />
            </a>
          </li>
        </ul>
      </header>
      
    </Headroom>
  );
}
export default Header;
