import React, {useContext,useState,useEffect} from "react";
import "./SplashScreen.css";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
//import {greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import splashScreen from "../../assets/lottie/graphNetworkWhiteAnimation";

export default function SplashScreen() {
  const {isDark} = useContext(StyleContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return (
    <div className={isDark ? "dark-mode splash-container" : "splash-container"}>
      <div className="splash-animation-container">
        <DisplayLottie animationData={splashScreen} animationHeight={windowWidth<1200 ? (windowWidth<1000 ? (windowWidth<769 ? (windowWidth<321 ? 250 : 350) : 450) : 500) : 600} />
      </div>
      {/*<div className="splash-title-container">
        <span className="splash-title">{greeting.username}</span>
      </div>
  */}
    </div>
  );
}
