import React, {useContext, useState, useEffect} from "react";
import SlidingPane from "react-sliding-pane";
import "./Terminal.scss";
import StyleContext from "../../contexts/StyleContext";
import Console from 'react-bash';

export default function Terminal({isTerminalOpen, onTerminalClose}) {
  const noop = () => {};
  
  const {isDark} = useContext(StyleContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    /*
    const keyDownHandler = event => {
      if (event.key === 'Escape' && isTerminalOpen) {
        event.preventDefault();
        onTerminalClose();
      }
    };
    */

    window.addEventListener('resize', handleWindowResize);
    //window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      //window.removeEventListener('keydown', keyDownHandler);
    };
  });

  const extensions = {
    sudo: {
        exec: ({ structure, history, cwd }) => {
            return { structure, cwd,
                history: history.concat({ value: 'Nice try... (ಠ(ಠ(ಠ_ಠ)ಠ)ಠ)' }),
            };
        },
    },
    clear: {
      exec: ({ structure, history, cwd }, command) => {
          return { structure, cwd, history: [] };
      },
    },
    exit: {
      exec: ({ structure, history, cwd }, command) => {
          onTerminalClose()
          return {};
      },
    },
};

  const history = 
  [
    { value: 'Welcome to the terminal! (work in progress)' },
    { value: 'Hackers will be high-fived. ( ‘-’)人(ﾟ_ﾟ )' },
    { value: 'Type `help` to begin' },
  ];

  const structure = {
    src: {
        file1: { content: 'This is the text content for <file1> of <src>' },
        file2: { content: 'This is the text content for <file2> of <src>' },
        childDir1: {
            file: { content: 'This is the text content for <file> of <src/childDir1>' },
        },
        childDir2: {
        }
    },
    '.hiddenDir': {
    },
    '.hiddenFile': { content: 'This is a hidden file' },
    file: { content: 'This is the text content for <file> of the root directory' },
    '.hidden': {
      file1: { content: 'The is the content for file1 in the <.hidden> directory.' },
      file2: { content: 'The is the content for file2 in the <.hidden> directory.' },
      dir2: {
          file: { content: 'The is the content for <file> in the <.hidden> directory.' },
      },
      '.secrets': { content: 'I\'m still afraid of the dark...' },
  },
    public: {
        file1: { content: 'The is the content for file1 in the <public> directory.' },
        file2: { content: 'The is the content for file2 in the <public> directory.' },
        file3: { content: 'The is the content for file3 in the <public> directory.' },
    },
    'README.md': { content: '✌⊂(✰‿✰)つ✌ Thanks for checking out the tool! There is a lot that you can do with react-bash and I\'m excited to see all of the fun commands and projects build on top of it!' },
  };

  const circle = {
    borderRadius: '50%',
    display: 'inline-block',
    height: '15px',
    marginRight: '5px',
    width: '15px',
  };
  
  const styles = {
    ReactBash: {
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: '\'Inconsolata\', monospace',
      fontSize: '13px',
      fontWeight: '400',
      height: '100%',
      overflow: 'hidden',
      textAlign: 'left',
    },
    body: {
      backgroundColor: '#000',
      color: 'white',
      flexGrow: 1,
      overflowY: 'scroll',
      padding: '10px',
    },
    header: {
      backgroundColor: '#dcdbdb',
      padding: '5px 10px 0',
    },
    prefix: {
      color: '#00f900',
      marginRight: '5px',
    },
    form: {
      display: 'flex',
    },
    redCircle: Object.assign({}, circle, {
        backgroundColor: '#bf616a',
    }),
    yellowCircle: Object.assign({}, circle, {
        //backgroundColor: '#ebcb8b',
        backgroundColor: 'transparent',
    }),
    greenCircle: Object.assign({}, circle, {
        //backgroundColor: '#a3be8c',
        backgroundColor: 'transparent',
    }),
  }
  

  return (
   <div>
      <SlidingPane
      // https://www.npmjs.com/package/react-sliding-pane?activeTab=readme
      isOpen={isTerminalOpen}
      from="bottom"
      className={isDark ? "dark-mode" : null}
      width={windowWidth.toString()}
      hideHeader={true}
      shouldCloseOnEsc={false}
      onRequestClose={onTerminalClose}
      preventScroll={true}
      >
      {/**/}
     
      <Console 
        extensions={extensions}
        history={history}
        structure={structure}
        prefix={"Guest@Claret.Tech"}
        theme={Console.Themes.DARK}
        styles={styles}
        onClose={onTerminalClose}
        onExpand={noop}
        onMinimize={noop}
      />
      
      </SlidingPane>
    </div>
  );
}
