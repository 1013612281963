import React, {useEffect, useState} from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import Research from "./research/Research"
import StackProgress from "./skillProgress/skillProgress";
import WorkExperience from "./workExperience/WorkExperience";
import Projects from "./projects/Projects";
import StartupProject from "./startupProjects/StartupProject";
import Achievement from "./achievement/Achievement";
import Papers from "./papers/Papers";
import Blogs from "./blogs/Blogs";
import Footer from "../components/footer/Footer";
import Talks from "./talks/Talks";
import Podcast from "./podcast/Podcast";
import Education from "./education/Education";
import ScrollToTopButton from "./topbutton/Top";
import Twitter from "./twitter-embed/twitter";
import Profile from "./profile/Profile";
import SplashScreen from "./splashScreen/SplashScreen";
import Terminal from "./terminal/Terminal";
import {splashScreen, terminalForced} from "../portfolio";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import "./Main.scss";

const Main = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] = useState(true);

  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  const [isTerminalOpen, setTerminalOpen] = useState(terminalForced.enabled);

  if (isTerminalOpen) {document.body.style.overflow = 'hidden';}else{setTimeout(
    () => document.body.style.overflow = 'unset',
    splashScreen.duration
  )}

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : (
          <>
            <Header 
              isTerminalOpen={isTerminalOpen}
              onTerminalShow={() => setTerminalOpen(true)}
              onTerminalClose={() => setTerminalOpen(false)}
              cursorBlink={!isTerminalOpen}
            />
            <Terminal 
              isTerminalOpen={isTerminalOpen}
              onTerminalClose={() => setTerminalOpen(false)}
            />
            <Greeting />
            <Skills />
            <Research />
            <StackProgress />
            <Projects />
            <Education />
            <Papers />
            <WorkExperience />
            <StartupProject />
            <Achievement />
            <Blogs />
            <Talks />
            <Twitter />
            <Podcast />
            <Profile />
            <Footer />
            <ScrollToTopButton />
          </>
        )}
      </StyleProvider>
    </div>
  );
};

export default Main;
